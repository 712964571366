<template>
    <div class="grid grid-cols-1 md:grid-cols-2 max-w-screen-lg pt-20 mx-auto gap-10 items-center px-10 lg:px-0">
            
            <div class="flex flex-row flex-1 max-w-xs md:max-w-xl md:order-last">
                <div class="w-full aspect-[251/544] flex-1 relative">
                    <nuxt-img format="webp" :src="$t('home.features.analyse.image_analyse')" quality="85" width="240" height="520" loading="lazy" alt="smoxy app screen analyse" class="device-image bg-gray-400" />  
                </div>
                <div class="w3r-dot rounded-2xl scale-90 flex-1 -translate-x-20 -z-10"></div>         
            </div>

            <div class="pt-2 md:pt-0 px-4 md:px-0">
                <p class="text-primary-500">{{$t('home.features.analyse.title')}}</p>
                <h3 class="text-3xl pr-4 font-bold font-owners text-dark-900">{{$t('home.features.analyse.headline')}}</h3>
                <p class="pt-4">{{$t('home.features.analyse.body')}}</p>
            </div>  

        </div>
</template>

<style>
.w3r-dot {
  background-image: radial-gradient(rgb(172, 172, 172) 5%, transparent 10%);
  background-size: 20px 20px;
  background-position: 0 0, 15px 15px;
  background-repeat: repeat;
}
</style>